import React from "react";
import { Link } from "react-router-dom";
import { errorMessageInfo } from "../../GlobalJson/Pages/Pages";

// ErrorArea Area
const ErrorMessages = () => {
  return (
    <>
      <section id="error_area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              {errorMessageInfo[0].map((item, index) => (
                <div className="error-img" style={{ marginTop: -70 }}>
                  <img src={item.img} alt="Error Imgs" />
                  <h3>{item.leadText}</h3>
                  <Link to="/" className="btn btn-theme">
                    {item.link}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ErrorMessages;
