export const contactInfo = [
  {
    leadText: "Contact Info",
  },
];

export const contactForm = [
  [
    {
      tag: "input",
      type: "text",
      name: "name",
      placeholder: "Name",
      classes: "form-control",
    },
  ],
  [
    {
      tag: "input",
      type: "email",
      name: "name",
      placeholder: "Email",
      classes: "form-control",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "subject",
      placeholder: "Subject",
      classes: "form-control",
    },
  ],
  [
    {
      tag: "textarea",
      type: "text",
      name: "subject",
      placeholder: "Type Your Messages...",
      classes: "form-control",
    },
  ],
  [
    {
      tag: "button",
      val: "Send",
    },
  ],
];

export const contactInfoData = [
  [
    // {
    //   name: "Our Address",
    //   address: "6th floor, anthina Barbosa Sidney",
    // },
  ],
  [
    {
      name: "Call us on",
      contact1: "Office: +689-203-7696",
      // contact2: "Customer Care: 1800-123-45-6789",
    },
  ],
  [
    {
      name: "Mail Us at",
      email1: "info@smallfleetdispatch.com",
      // email2: "Wesupportyou@demo.com",
    },
  ],
];
