import React from "react";
import { subscribeData } from "../../GlobalJson/Home/Home";
// Subscribe Area
const Subscribe = () => {
  return (
    <>
      <section id="subscribe_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              {subscribeData.map((item, index) => (
                <div className="subscribe_img" key={index}>
                  <img src={item.img} alt="scribe_img" />
                </div>
              ))}
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 col-12">
              <div className="subscribe-area-inner">
                {subscribeData.map((item, index) => (
                  <>
                    <div className="heading-left-border" key={index}>
                      <h2>{item.leadText}</h2>
                    </div>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Recipient's email"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn  btn-theme"
                          type="button"
                          id="button-addon2"
                        >
                          {item.button}
                        </button>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscribe;
