import React from "react";

import ServiceDetailsMain from "../component/ServiceDetails";

const ServiceDetails = () => {
  return (
    <div style={{ padding: 50 }}>
      <ServiceDetailsMain />
    </div>
  );
};

export default ServiceDetails;
