import React from "react";
import { Link } from "react-router-dom";
// Import FormInput
import FormInput from "../Common/FormInput";
import { signOutInfo } from "../../GlobalJson/Pages/Pages";

const SignUpForm = (props) => {
  return (
    <>
      <section id="signIn_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
              <div className="user_area_wrapper">
                <h2>{props.heading}</h2>
                <div className="user_area_form ">
                  <form action="#!" id="form_signIn">
                    <div className="row">
                      {signOutInfo[1].map((item, index) => (
                        <div className="col-lg-12">
                          <FormInput
                            key={index}
                            tag={item.tag}
                            type={item.type}
                            name={item.name}
                            classes={item.classes}
                            placeholder={item.placeholder}
                          />
                        </div>
                      ))}

                      {signOutInfo[2].map((item, index) => (
                        <div className="col-lg-12">
                          <FormInput
                            key={index}
                            tag={item.tag}
                            type={item.type}
                            name={item.name}
                            classes={item.classes}
                            placeholder={item.placeholder}
                          />
                        </div>
                      ))}
                      {signOutInfo[3].map((item, index) => (
                        <div className="col-lg-12">
                          <FormInput
                            key={index}
                            tag={item.tag}
                            type={item.type}
                            name={item.name}
                            classes={item.classes}
                            placeholder={item.placeholder}
                          />
                        </div>
                      ))}
                      {signOutInfo[4].map((item, index) => (
                        <div className="col-lg-12">
                          <FormInput
                            key={index}
                            tag={item.tag}
                            type={item.type}
                            name={item.name}
                            classes={item.classes}
                            placeholder={item.placeholder}
                          />
                        </div>
                      ))}
                      {signOutInfo[5].map((item, index) => (
                        <div className="col-lg-12">
                          <FormInput
                            key={index}
                            tag={item.tag}
                            type={item.type}
                            name={item.name}
                            classes={item.classes}
                            placeholder={item.placeholder}
                          />
                        </div>
                      ))}
                      {signOutInfo[6].map((item, index) => (
                        <div className="col-lg-12">
                          <FormInput
                            key={index}
                            tag={item.tag}
                            type={item.type}
                            name={item.name}
                            classes={item.classes}
                            placeholder={item.placeholder}
                          />
                        </div>
                      ))}
                      <div className="col-lg-12">
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                          />
                          {signOutInfo[7].map((item, index) => (
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                              key={index}
                            >
                              {item.text}
                            </label>
                          ))}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="submit_button">
                          {signOutInfo[8].map((item, index) => (
                            <FormInput
                              key={index}
                              tag={item.tag}
                              val={item.subText}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="not_remember_area">
                          {signOutInfo[9].map((item, index) => (
                            <p key={index}>
                              {item.text1}
                              <Link to="/signIn"> {item.text2}</Link>
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpForm;
