import React from "react";
import {
  pageRequestHeader,
  pageRequestData,
  pageRequestData2,
} from "../../GlobalJson/Pages/Pages";
import FormInput from "../Common/FormInput";

const RequestQuoteForm = () => {
  let options1 = [
    {
      text: "Freight Type",
      value: "",
    },
    {
      text: "Type 1",
      value: "",
    },
    {
      text: "Type 2",
      value: "",
    },
    {
      text: "Type 3",
      value: "",
    },
    {
      text: "Type 4",
      value: "",
    },
  ];
  let options2 = [
    {
      text: "Incoterms",
      value: "",
    },
    {
      text: "Type 1",
      value: "",
    },
    {
      text: "Type 2",
      value: "",
    },
    {
      text: "Type 3",
      value: "",
    },
    {
      text: "Type 4",
      value: "",
    },
  ];
  return (
    <>
      <section id="request_quote_form_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12 col-12">
              <form id="request_form">
                <div className="row">
                  <div className="col-lg-12">
                    {pageRequestHeader.map((item, index) => (
                      <div className="heading_quote">
                        <h3>{item.leadText}</h3>
                      </div>
                    ))}
                  </div>
                  <div className="col-lg-6">
                    {pageRequestData[0].map((item, index) => (
                      <FormInput
                        key={index}
                        tag={item.tag}
                        classes={item.classes}
                        options={options1}
                        label={item.label}
                      />
                    ))}
                  </div>
                  <div className="col-lg-6">
                    {pageRequestData[1].map((item, index) => (
                      <FormInput
                        tag={item.tag}
                        classes={item.classes}
                        options={options2}
                        label={item.label}
                      />
                    ))}
                  </div>
                  <div className="col-lg-6">
                    {pageRequestData[2].map((item, index) => (
                      <FormInput
                        tag={item.tag}
                        type={item.type}
                        name={item.name}
                        classes={item.classes}
                        placeholder={item.placeholder}
                        label={item.label}
                      />
                    ))}
                  </div>
                  <div className="col-lg-6">
                    {pageRequestData[3].map((item, index) => (
                      <FormInput
                        tag={item.tag}
                        type={item.type}
                        name={item.name}
                        classes={item.classes}
                        placeholder={item.placeholder}
                        label={item.label}
                      />
                    ))}
                  </div>
                  <div className="col-lg-6">
                    {pageRequestData[4].map((item, index) => (
                      <FormInput
                        tag={item.tag}
                        type={item.type}
                        name={item.name}
                        classes={item.classes}
                        placeholder={item.placeholder}
                        label={item.label}
                      />
                    ))}
                  </div>
                  <div className="col-lg-6">
                    {pageRequestData[5].map((item, index) => (
                      <FormInput
                        key={index}
                        tag={item.tag}
                        type={item.type}
                        name={item.name}
                        classes={"form-control"}
                        placeholder={item.placeholder}
                        label={item.label}
                      />
                    ))}
                  </div>
                  <div className="col-lg-12">
                    {pageRequestData2[0].map((item, index) => (
                      <div className="heading_quote arae_top" key={index}>
                        <h3>{item.leadText}</h3>
                      </div>
                    ))}
                  </div>

                  <div className="col-lg-6">
                    {pageRequestData2[1].map((item, index) => (
                      <FormInput
                        key={index}
                        tag={item.tag}
                        type={item.type}
                        name={item.name}
                        classes={item.classes}
                        placeholder={item.placeholder}
                        label={item.label}
                      />
                    ))}
                  </div>
                  <div className="col-lg-6">
                    {pageRequestData2[2].map((item, index) => (
                      <FormInput
                        tag={item.tag}
                        type={item.type}
                        name={item.name}
                        classes={item.classes}
                        placeholder={item.placeholder}
                        label={item.label}
                      />
                    ))}
                  </div>
                  <div className="col-lg-6">
                    {pageRequestData2[3].map((item, index) => (
                      <FormInput
                        key={index}
                        tag={item.tag}
                        type={item.type}
                        name={item.name}
                        classes={item.classes}
                        placeholder={item.placeholder}
                        label={item.label}
                      />
                    ))}
                  </div>
                  <div className="col-lg-6">
                    {pageRequestData2[4].map((item, index) => (
                      <FormInput
                        key={index}
                        tag={item.tag}
                        type={item.type}
                        name={item.name}
                        classes={item.classes}
                        placeholder={item.placeholder}
                        label={item.label}
                      />
                    ))}
                  </div>
                  <div className="col-lg-12">
                    {pageRequestData2[5].map((item, index) => (
                      <FormInput
                        key={index}
                        tag={item.tag}
                        type={item.type}
                        name={item.name}
                        classes={item.classes}
                        placeholder={item.placeholder}
                        label={item.label}
                      />
                    ))}
                  </div>
                  <div className="col-lg-12">
                    {pageRequestData2[6].map((item, index) => (
                      <div className="quote_submit_button" key={index}>
                        <button className="btn btn-theme">{item.button}</button>
                      </div>
                    ))}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RequestQuoteForm;
