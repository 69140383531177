import React from "react";
import { Link } from "react-router-dom";
import { serviceData } from "../../GlobalJson/Services/Services";
// ServiceSideBar Area
const ServiceSideBar = () => {
  return (
    <>
      <div className="col-lg-4">
        <div className="service_details_sidebar">
          <div className="sidebar_service_wrappers">
            {serviceData[1].map((data, index) => (
              <h3 key={`${index}-service-list`}>{data.leadText}</h3>
            ))}
            <ul className="list-group">
              {serviceData[2].map((data, index) => (
                <li key={`${index}-service-list-1`}><Link to={data.url}>{data.text}</Link></li>
              )
                  
              )}
            </ul>
          </div>
          {serviceData[3].map((data, index) => (
            <div className="sidebar_service_wrappers" key={`${index}-service-list-2`}>
              <h3>{data.leadText}</h3>
              <div className="contact_sidebar">
                <h6>{data.office}</h6>
                <p>{data.address}</p>
              </div>
              <div className="contact_sidebar">
                <h6>{data.subLeadText}</h6>
                <p>{data.text1}</p>
                <p>{data.text2}</p>
              </div>
              <div className="contact_sidebar">
                <h6>{data.mailText}</h6>
                <p>{data.mail}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiceSideBar;
