import React from "react";
//Import FormInput
import FormInput from "../Common/FormInput";

import { contactForm } from "../../GlobalJson/Contact/Contact";
// ContactForm Area
const ContactForm = () => {
  return (
    <>
      <form action="#!">
        <div className="row">
          <div className="col-lg-12">
            {contactForm[0].map((item, index) => (
              <FormInput
                key={index}
                tag={item.tag}
                type={item.type}
                name={item.name}
                classes={item.classes}
                placeholder={item.placeholder}
              />
            ))}
          </div>
          <div className="col-lg-12">
            {contactForm[1].map((item, index) => (
              <FormInput
                key={index}
                tag={item.tag}
                type={item.type}
                name={item.name}
                classes={item.classes}
                placeholder={item.placeholder}
              />
            ))}
          </div>
          <div className="col-lg-12">
            {contactForm[2].map((item, index) => (
              <FormInput
                key={index}
                tag={item.tag}
                type={item.type}
                name={item.name}
                classes={item.classes}
                placeholder={item.placeholder}
              />
            ))}
          </div>
          <div className="col-lg-12">
            {contactForm[3].map((item, index) => (
              <FormInput
                key={index}
                tag={item.tag}
                type={item.type}
                name={item.name}
                classes={item.classes}
                placeholder={item.placeholder}
              />
            ))}
          </div>
          <div className="col-lg-12">
            <div className="contact_form_submit">
              {contactForm[4].map((item, index) => (
                <FormInput key={index} tag={item.tag} val={item.val} />
              ))}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
