import React from "react";
//Import SectionHeading
import SectionHeading from "../Common/SectionHeading";
//Import FormInput
import FormInput from "../Common/FormInput";

import {
  trackShipHeader,
  trackShipData,
  truckShipDate2,
} from "../../GlobalJson/Pages/Pages";

const TrackShipment = () => {
  let options1 = [
    {
      text: "Select an option...",
      value: "",
    },
    {
      text: "Khulna",
      value: "",
    },
    {
      text: "Dhaka",
      value: "",
    },
    {
      text: "Barisal",
      value: "",
    },
    {
      text: "Fani",
      value: "",
    },
  ];

  return (
    <>
      <section id="track_shipment_area">
        <div className="container">
          {trackShipHeader.map((item, index) => (
            <SectionHeading
              key={index}
              heading={item.leadText}
              para={item.subText}
            />
          ))}

          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12 col-12">
              <div className="track_area_form">
                <form action="#!" id="track_form_area">
                  {/* {trackShipData[0].map((item, index) => (
                    <FormInput
                      key={index}
                      tag={item.tag}
                      type={item.type}
                      name={item.name}
                      classes={item.classes}
                      placeholder={item.placeholder}
                      label={item.label}
                    />
                  ))} */}

                  {/* {trackShipData[1].map((item, index) => (
                    <FormInput
                      key={index}
                      tag={item.tag}
                      type={item.type}
                      name={item.name}
                      classes={item.classes}
                      placeholder={item.placeholder}
                      label={item.label}
                    />
                  ))} */}
                  {trackShipData[2].map((item, index) => (
                    <FormInput
                      key={index}
                      tag={item.tag}
                      type={item.type}
                      name={item.name}
                      classes={item.classes}
                      placeholder={item.placeholder}
                      label={item.label}
                    />
                  ))}
                  {/* <div className="form-group">
                    {truckShipDate2[0].map((item, index) => (
                      <div key={index}>
                        <label>{item.text1}</label>
                        <div className="input-group">
                          <input type="date" className="form-control" />
                          <span>{item.text2}</span>
                          <input type="date" className="form-control" />
                        </div>
                      </div>
                    ))}
                  </div> */}
                  {/* {truckShipDate2.map((item, index) => (
                    <FormInput
                      key={index}
                      tag={item.tag}
                      classes={item.classes}
                      label={item.label}
                      options={options1}
                    />
                  ))} */}

                  {/* <div className="form-group">
                    {truckShipDate2[2].map((item, index) => (
                      <label key={index}>{item.leadText}</label>
                    ))}
                    <div className="input-group">
                      {truckShipDate2[3].map((item, index) => (
                        <div className="form-group">
                          <input
                            key={index}
                            type={item.type}
                            id={item.id}
                            name={item.name}
                            value={item.value}
                          />
                          <label>{item.label}</label>
                          <br />
                        </div>
                      ))}
                      {truckShipDate2[4].map((item, index) => (
                        <div className="form-group">
                          <input
                            key={index}
                            type={item.type}
                            id={item.id}
                            name={item.name}
                            value={item.value}
                          />
                          <label>{item.label}</label>
                          <br />
                        </div>
                      ))}
                      {truckShipDate2[5].map((item, index) => (
                        <div className="form-group">
                          <input
                            key={index}
                            type={item.type}
                            id={item.id}
                            name={item.name}
                            value={item.value}
                          />
                          <label>{item.label}</label>
                          <br />
                        </div>
                      ))}
                    </div>
                  </div> */}
                  <div className="track_now_btn">
                    {truckShipDate2[6].map((item, index) => (
                      <button className="btn btn-theme" key={index}>
                        {item.button}
                      </button>
                    ))}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default TrackShipment;
