export const TopHeaderData = [
  [
    {
      icon: "far fa-envelope",
      text: "info@smallfleetdispatch.com",
      link: "#",
    },
    {
      icon: "fas fa-phone-volume",
      text: "+689-203-7696",
      link: "#",
    },
  ],
  [
    {
      link: "/track-shipment",
      text: "Track Shipment",
    },
    {
      link: "/contact",
      text: "Support",
    },
    // {
    //   link: "/blog",
    //   text: "News & Media",
    // },
  ],
];

export const MenuData = [
  {
    name: "Home",
    href: "/",
    has_children: false,
  },
  {
    name: "About",
    href: "/about",
    has_children: false,
  },
  {
    name: "Services",
    href: "/services",
    has_children: false,
  },
  // {
  //   name: "News",
  //   href: "/news",
  //   has_children: false,
  // },
  {
    name: "Contact",
    href: "/contact",
    has_children: false,
  },
];
