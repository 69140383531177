import img1 from "../../assets/img/logistic/plane.png";
import img2 from "../../assets/img/logistic/shipment.png";
import img3 from "../../assets/img/logistic/truck-delivery.png";
import img4 from "../../assets/img/home1/about.png";
import img5 from "../../assets/img/home1/sign.png";
import img6 from "../../assets/img/svg/house.svg";
import img7 from "../../assets/img/svg/crm.svg";
import img8 from "../../assets/img/svg/clock.svg";
import img9 from "../../assets/img/home1/parcel.png";
import img19 from "../../assets/img/logo.png";

import aboutImg from '../../assets/img/home/about.png';
import footerLogo from '../../assets/img/LogoWhite.png';

// Sliders
export const slider1 = [
  {
    headerText: "Shipping Products",
    brText: "Worldwide",
    text: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore magna aliqua quisipsum suspendisse.",
    button: "Get A Quote",
  },
];

export const slider2 = [
  {
    headerText: "Shipping Products",
    brText: "Worldwide",
    text: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore magna aliqua quisipsum suspendisse.",
    button: "Get A Quote",
  },
];

export const slider3 = [
  {
    headerText: "Shipping Products",
    brText: "Worldwide",
    text: " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed eiusmod tempor incididunt ut labore et dolore magna aliqua quisipsum suspendisse.",
    button: "Get A Quote",
  },
];

// Social Icons
export const socialIcon = [
  {
    link: "#",
    icon: "fab facebook fa-facebook-f",
  },
  {
    link: "#",
    icon: "fab twitter fa-twitter",
  },
  {
    link: "#",
    icon: "fab instagram fa-instagram",
  },
  {
    link: "#",
    icon: "fab linkedin fa-linkedin-in",
  },
];

export const schedule = [
  {
    leadText: "Get a Schedule",
    text: "Solving your supply chain needs from end to end, taking the complexity out of container shipping. We are at the forefront of developing innovation.",
  },
];

export const form__shipment = [
  {
    shipment: "Track My Shipment",
    container: "Containers no or B/L",
    email: "Email Address",
    button: "Track Shipment ",
  },
];

export const form__schedule = [
  {
    schedule: "Get a Schedule",
    container: "Containers no or B/L",
    email: "Email Address",
    button: "Track Shipment ",
  },
];

export const logistic = [
  {
    leadText: "Logistics Services",
    text: "Solving your supply chain needs from end to end, taking the complexity out of container shipping. We are at the forefront of developing innovation.",
    buttonOne: "Business Logistics",
    buttonTwo: "Military Logistics",
    buttonThree: "Event Logistics",
  },
];

//Images
export const LogisticsData = [
  [
    {
      img: img1,
      heading: "1.Truck Delivery",
      para: "We offer a Global Logistics Network with our worldwide office",
    },
    {
      img: img2,
      heading: "2.Truck Delivery",
      para: "We offer a Global Logistics Network with our worldwide office",
    },
    {
      img: img3,
      heading: "3.Truck Delivery",
      para: "We offer a Global Logistics Network with our worldwide office",
    },
  ],
];

export const linkIcon = [
  {
    icon: " fas fa-link",
    link: "#",
  },
  {
    icon: " fas fa-link",
    link: "#",
  },
  {
    icon: " fas fa-link",
    link: "#",
  },
];

export const globalLeader = [
  {
    img: aboutImg,
    leadText: "Global Leader of the Logistics",
    subText:
      "All our efforts and investments are geared towards offering better solutions.",
    text: "Solving your supply chain needs from end to end, taking the complexity out of container shipping. We are at the forefront of developing innovative supply chain solutions.",
    name: "Timothy Jones",
    // post: "Chairman, Transtics",
    // signature: img5,
  },
];

// Counter Data
export const CounterData = [
  {
    countStart: 100,
    countEnd: 12345,
    heading: "COMPANY ESTABLISHED",
    icon: " fas fa-building",
  },
  {
    countStart: 100,
    countEnd: 12345,
    heading: "COUNTRIES SERVED",
    icon: "fas fa-globe",
  },
  {
    countStart: 100,
    countEnd: 12345,
    heading: "PORTS SERVED",
    icon: " fas fa-building",
  },
];

export const OurAdventagesData = [
  [
    {
      img: img6,
      heading: "Communication",
      para: "In the trucking industry, proper communication remains to be an essential fundamental of effective transportation movements. Our transportation management systems provide robust communication systems from start to finish covering all aspects of your back-office functionality; to keeping customers updated and reporting any changes, advising on inclement weather and re-routing and much more. SF Dispatch represents your business in the most professional manner, understanding that growing your business is our only goal",
    },
    {
      img: img7,
      heading: "No More Paperwork",
      para: "Remove all the hassle of paperwork. At SF Dispatch your dispatcher keeps you organized and your business operationally sound. Leave the paper shuffle to us!",
    },
    {
      img: img8,
      heading: "Focused",
      para: "SF Dispatch provides best in industry dispatcher’s that negotiates your freight opportunities with some of the top brokers and buyers in the industry. Above all we are focused on our drivers and maintaining a positive working relationship with all parties and building your transportation business.",
    },
  ],
];

export const mapArea = [
  {
    leadText: "Ship Smart with Transtics",
    text: "Solving your supply chain needs from end to end, taking the complexity out of container shipping. We are at the forefront of developing innovative supply chain solutions",
  },
];

export const clientReview = [
  {
    leadText: "Client's Review",
    text: "Solving your supply chain needs from end to end, taking the complexity out of container shipping. We are at the forefront of developing innovation.",
  },
];

export const testimonialButton = [
  {
    button: "More Review",
    link: "#",
  },
];

export const pricingPlan = [
  {
    leadText: "Our Pricing Plan",
    text: "Solving your supply chain needs from end to end, taking the complexity out of container shipping. We are at the forefront of developing innovation.",
  },
];

export const pricingData = [
  {
    title: "Basic Plan",
    amount: "8.9",
    items: [
      { icons: "fa fa-check", text: "Standard Feature" },
      { icons: "fa fa-check", text: "Lifetime free support" },
      { icons: "fa fa-times", text: "Extra features" },
      { icons: "fa fa-check", text: "Upgrate options" },
      { icons: "fa fa-check", text: "Full access" },
    ],
  },
  {
    title: "Popular Plan",
    amount: "29.9",
    items: [
      { icons: "fa fa-check", text: "Standard Feature" },
      { icons: "fa fa-check", text: "Lifetime free support" },
      { icons: "fa fa-times", text: "Extra features" },
      { icons: "fa fa-check", text: "Upgrate options" },
      { icons: "fa fa-check", text: "Full access" },
    ],
  },
  {
    title: "Premium Plan",
    amount: "88.9",
    items: [
      { icons: "fa fa-check", text: "Standard Feature" },
      { icons: "fa fa-check", text: "Lifetime free support" },
      { icons: "fa fa-times", text: "Extra features" },
      { icons: "fa fa-check", text: "Upgrate options" },
      { icons: "fa fa-check", text: "Full access" },
    ],
  },
];

export const subscribeData = [
  {
    img: img9,
    leadText: "Subscribe for Offers and News",
    button: "Subscribe",
  },
];

export const blogDataLead = [
  {
    leadText: "Recent News",
    text: "Solving your supply chain needs from end to end, taking the complexity out of container shipping. We are at the forefront of developing innovation",
  },
];

export const footerWedgetData = [
  {
    img: footerLogo,
    text: "Remove all the hassle of paperwork. At SF Dispatch your dispatcher keeps you organized and your business operationally sound. Leave the paper shuffle to us!",
  },
];

export const FooterItems = [
  {
    title: "Quick Link",
    links: [
      { linkTitle: "Home", link: "/" },
      { linkTitle: "About Company", link: "/about" },
      { linkTitle: "Our Services", link: "/services" },
      { linkTitle: "Contact us", link: "/contact" },
    ],
  },
  {
    title: "Company",
    links: [
      // { linkTitle: "Business Consulting", link: "#" },
      // { linkTitle: "Testimonials", link: "#" },
      { linkTitle: "Track Your Shipment", link: "#" },
      { linkTitle: "Recent News", link: "/blog" },
      { linkTitle: "Privacy Policy", link: "/privacyPolicy" },
      { linkTitle: "Terms & Condition", link: "/terms" },
    ],
  },
];

export const footerContact = [
  {
    title: "Contact Info",
    // address: "6th Floor Barbosa, Sidney",
    email1: " info@smallfleetdispatch.com",
    // email2: " contact@floza.com",
    number: "+689-203-7696",
    days: "Mon - Sat: 9:00 - 18:00",
  },
];

export const copyRight = [
  {
    leadText: "Copyright @2021. ",
    leadTextSpam: "Small Fleet Dispatch.",
    lastText: "All Rights Reserved ",
  },
];
