import React from "react";
import { copyRight } from "../../GlobalJson/Home/Home";
// CopyRight Area
const CopyRight = () => {
  return (
    <>
      <div id="copy_right">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {copyRight.map((item, index) => (
                <div className="copy-right-area" key={index}>
                  <p>
                    {item.leadText}
                    <span>{item.leadTextSpam}</span> {item.lastText}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CopyRight;
