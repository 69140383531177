import React from "react";
import { Link } from "react-router-dom";
import {
  footerWedgetData,
  FooterItems,
  footerContact,
} from "../../GlobalJson/Home/Home";

const Footer = () => {
  return (
    <>
      <footer id="footer_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="footer_wedget">
                {footerWedgetData.map((item, index) => (
                  <div>
                    <img src={item.img} alt="logo_img" />
                    <p>{item.text}</p>
                  </div>
                ))}
                <div className="footer_social_icon">
                  <a href="#!">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#!">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#!">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a href="#!">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
              <div className="footer_wedget">
                {FooterItems.slice(0, 1).map((data, index) => (
                  <FooterWidget data={data} key={index} />
                ))}
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="footer_wedget">
                {FooterItems.slice(1, 2).map((data, index) => (
                  <FooterWidget data={data} key={index} />
                ))}
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-12">
              <div className="footer_wedget">
                {footerContact.map((item, index) => (
                  <>
                    <h4>{item.title}</h4>
                    <div className="contact-info-footer">
                      <ul>
                        {/* <li>
                          <i className="fas fa-map-marker-alt"></i>
                          {item.address}
                        </li> */}
                        <li>
                          <i className="far fa-envelope"></i>
                          <a href="#">{item.email1}</a>
                        </li>
                        {/* <li>
                          <i className="far fa-envelope"></i>
                          <a href="mailto:hello@demo.com">{item.email2}</a>
                        </li> */}
                        <li>
                          <i className="fas fa-phone-volume"></i>
                          <a href="#">{item.number}</a>
                        </li>
                        <li>
                          <i className="far fa-clock"></i>
                          {item.days}
                        </li>
                      </ul>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

export const FooterWidget = ({ data }) => (
  <>
    <h4>{data.title}</h4>
    <ul>
      {data.links.map((datas, index) => (
        <li key={index}>
          <Link to={datas.link}>{datas.linkTitle}</Link>
        </li>
      ))}
    </ul>
  </>
);
