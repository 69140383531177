import React from "react";
// import CommonBanner from '../component/Common/Banner'
import SignInForm from "../component/SignIn";
import OurPartner from "../component/Common/OurPartner";
import { signInInfo } from "../GlobalJson/Pages/Pages";

const SignIn = () => {
  return (
    <>
      {/* <CommonBanner heading="SignIn" page="SignIn"/> */}
      {signInInfo[0].map((item, index) => (
        <SignInForm key={index} heading={item.leadText} />
      ))}

      <OurPartner />
    </>
  );
};

export default SignIn;
