import React from "react";
import { privacyPolicyInfo } from "../../GlobalJson/Pages/Pages";
// PrivacyPolicy Area
const OurPolicies = () => {
  return (
    <>
      <section id="privacy_policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {privacyPolicyInfo[0].map((item, index) => (
                <div className="text_heading_para" key={index}>
                  <h3>{item.leadText}</h3>
                  <p>{item.para1}</p>
                  <p>{item.para2}</p>
                </div>
              ))}

              {privacyPolicyInfo[1].map((item, index) => (
                <div className="text_heading_para" key={index}>
                  <h3>{item.leadText}</h3>
                  <p>{item.para1}</p>
                  <p>{item.para2}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurPolicies;
