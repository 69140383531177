import OurServicesImg from '../../assets/img/services/ourservices.png';

export const serviceData = [
  [
    {
      img: OurServicesImg,
      title: "Dispatch Programs",
      service1: 'Pay & Go',
      service2: 'Dispatch 10-4',
      service3: 'Fleet Manager',
      titleText:
        "All programs require a one-time set up fee of $250. All payments are made electronically on our website or ACH. No payments are made over the phone.",
      serviceItem1: [
        'No contracts involved',
        'Obtain the highest paid loads',
        'Run Credit for brokers and shippers',
        'Assist with carrier set up',
        'Fax all necessary paperwork',
        'Send copies of rate confirmations and correspondence',
      ],
      serviceItem2: [
        'personal assigned dispatcher English or Spanish speaking.',
        'Keeps track of every load. Loaded and empty miles',
        'Obtain highest paying loads and negotiation with trusted brokers',
        'Run credit reports of all brokers and shippers',
        'Carrier set up',
        'Send copies of confirmations & correspondence',
        'Road side assistance and direction',
        'Weekly revenue reports',
        '24/7/365 Support'
      ],
      serviceItem3: [
        'We manage and grow your business! Email for inquiries'
      ]
    },
  ],

  [
    {
      leadText: "Our Service",
    },
  ],
  [
    {
      text: "Pay & Go",
      url: "#",
    },
    {
      text: "Dispatch 10-4",
      url: "#",
    },
    {
      text: "Fleet Manager",
      url: "#",
    },
  ],
  [
    {
      leadText: "Contact Us",
      // office: "Visit our office",
      // address: "244, Cargo Hub, Los Angeles, California- 90005",
      subLeadText: "Call us on",
      text1: "Office: +689-203-7696",
      // text2: "Tollfree: 1800-123-45-6789",
      mailText: "Mail Us at",
      mail: "info@smallfleetdispatch.com",
    },
  ],
];

// *************** Please Ignore
export const weServe = [
  {
    leadText: "We Serve Various Ways",
    text: "Solving your supply chain needs from end to end, taking the complexity out of container shipping. We are at the forefront of developing innovation",
  },
];
