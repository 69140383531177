import React from "react";
import { contactInfoData } from "../../GlobalJson/Contact/Contact";
//ContactInfo Area
const ContactInfo = () => {
  return (
    <>
      <div className="contact_info_wrapper">
        {contactInfoData[0].map((item, index) => (
          <div className="contact_item" key={index}>
            <h5>{item.name}</h5>
            <p>{item.address}</p>
          </div>
        ))}
        {contactInfoData[1].map((item, index) => (
          <div className="contact_item">
            <h5>{item.name}</h5>
            <p>{item.contact1}</p>
            <p>{item.contact2}</p>
          </div>
        ))}
        {contactInfoData[2].map((item, index) => (
          <div className="contact_item">
            <h5>{item.name}</h5>
            <p>{item.email1}</p>
            <p>{item.email2}</p>
          </div>
        ))}
      </div>
    </>
  );
};

export default ContactInfo;
