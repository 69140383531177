import React from "react";
import { serviceData } from "../../GlobalJson/Services/Services";

const ServiceContent = () => {
  return (
    <>
      <div className="col-lg-8">
        <div className="service_details_wrapper">
          {serviceData[0].map((data, index) => (
            <div className="service_details_items" key={`${index}-services`}>
              <img src={data.img} alt="img" />
              <div className="service_details_para">
                <h3>{data.title}</h3>
                <p>{data.titleText}</p>
                <div style={{ paddingTop: 50 }}>
                  <h3>{data.service1}</h3>
                  <ul className="list-group list-group-flush">
                    {data.serviceItem1.map((item) => (
                      <li className="list-group-item"><p>{item}</p></li>
                      ))}
                  </ul>
                </div>
                <div style={{ paddingTop: 50 }}>
                  <h3>{data.service2}</h3>
                  <ul className="list-group list-group-flush">
                    {data.serviceItem2.map((item) => (
                      <li className="list-group-item"><p>{item}</p></li>
                      ))}
                  </ul>
                </div>
                <div style={{ paddingTop: 50 }}>
                  <h3>{data.service3}</h3>
                  <ul className="list-group list-group-flush">
                    {data.serviceItem3.map((item) => (
                      <li className="list-group-item"><p>{item}</p></li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiceContent;
