import React from "react";
import { Link } from "react-router-dom";
import { aboutData } from "../../GlobalJson/About/About";
// ServiceSideBar Area
const ServiceSideBar = () => {
  return (
    <>
      <div className="col-lg-4">
        <div className="service_details_sidebar">
          {aboutData[3].map((data, index) => (
            <div className="sidebar_service_wrappers" key={index}>
              <h3>{data.leadText}</h3>
              <div className="contact_sidebar">
                <h6>{data.office}</h6>
                <p>{data.address}</p>
              </div>
              <div className="contact_sidebar">
                <h6>{data.subLeadText}</h6>
                <p>{data.text1}</p>
                <p>{data.text2}</p>
              </div>
              <div className="contact_sidebar">
                <h6>{data.mailText}</h6>
                <p>{data.mail}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiceSideBar;
