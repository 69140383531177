import React from "react";
import { Link } from "react-router-dom";
// Import FormInput
import FormInput from "../Common/FormInput";
import { signInInfo } from "../../GlobalJson/Pages/Pages";

const SignInForm = (props) => {
  return (
    <>
      <section id="signIn_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
              <div className="user_area_wrapper">
                <h2>{props.heading}</h2>
                <div className="user_area_form ">
                  <form action="#!" id="form_signIn">
                    <div className="row">
                      <div className="col-lg-12">
                        {signInInfo[1].map((item, index) => (
                          <FormInput
                            tag={item.tag}
                            type={item.type}
                            name={item.name}
                            classes={item.classes}
                            placeholder={item.placeholder}
                          />
                        ))}
                      </div>
                      <div className="col-lg-12">
                        {signInInfo[2].map((item, index) => (
                          <FormInput
                            tag={item.tag}
                            type={item.type}
                            name={item.name}
                            classes={item.classes}
                            placeholder={item.placeholder}
                          />
                        ))}
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="exampleCheck1"
                          />
                          {signInInfo[3].map((item, index) => (
                            <label
                              className="form-check-label"
                              htmlFor="exampleCheck1"
                              key={index}
                            >
                              {item.text}
                            </label>
                          ))}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        {signInInfo[4].map((item, index) => (
                          <div className="submit_button">
                            <FormInput
                              key={index}
                              tag={item.button}
                              val={item.text}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="col-lg-12">
                        <div className="not_remember_area">
                          {signInInfo[5].map((item, index) => (
                            <p>
                              {item.text}
                              <Link to="/signup">{item.subText}</Link>
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignInForm;
