import React from "react";
import { termsConditionInfo } from "../../GlobalJson/Pages/Pages";
// TermsCondition  Area
const TermsConditionContent = () => {
  return (
    <>
      <section id="privacy_policy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {termsConditionInfo[0].map((item, index) => (
                <div className="text_heading_para" key={index}>
                  <h3>{item.leadText}</h3>
                  <p>{item.para1}</p>
                  <p>{item.para1}</p>
                </div>
              ))}

              {termsConditionInfo[1].map((item, index) => (
                <div className="text_heading_para" key={index}>
                  <h3>{item.leadText}</h3>
                  <p>{item.para1}</p>
                  <p>{item.para1}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsConditionContent;
