import React from "react";
import ErrorMessages from "../component/Error";

const Error = () => {
  return (
    <>
      <ErrorMessages />
    </>
  );
};

export default Error;
