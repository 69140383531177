import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { socialIcon } from "../../../GlobalJson/Home/Home";

const Jumbotron = () => {
  const [social] = useState(socialIcon);

  return (
    <>
      <section id="homeOne_banner">
        <div className="banner-slider">
          <div className="banner-item jumbotron-img">
              <div className="container">
                <div className="banner_one_inner">
                  <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                      <div className="banner-text">
                        {/* <Link className="btn btn-theme" to="#">GET QUOTE</Link> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className="banner_social_icon">
          <ul>
            {social.map((item, index) => {
              return (
                <li>
                  <a href={item.link} key={index}>
                    <i className={item.icon}></i>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </>
  );
};

export default Jumbotron;
