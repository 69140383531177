import img1 from "../../assets/img/common/team1.png";
import img2 from "../../assets/img/common/team2.png";
import img3 from "../../assets/img/common/team3.png";
import img4 from "../../assets/img/common/team4.png";
import img5 from "../../assets/img/common/feedback2.jpg";
import img6 from "../../assets/img/common/feedback2.jpg";
import img7 from "../../assets/img/common/feedback3.jpg";
import img8 from "../../assets/img/gallery/gallery-1.jpg";
import img9 from "../../assets/img/gallery/gallery-2.jpg";
import img10 from "../../assets/img/gallery/gallery-3.jpg";
import img11 from "../../assets/img/gallery/gallery-4.jpg";
import img12 from "../../assets/img/gallery/gallery-5.jpg";
import img13 from "../../assets/img/gallery/gallery-6.jpg";
import img14 from "../../assets/img/404.jpg";

export const TeamData = [
  {
    img: img1,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Kelina Mar",
    des: "Creative Director",
  },
  {
    img: img2,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Shamrat Dor",
    des: "Executive",
  },
  {
    img: img3,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Kazi Des",
    des: "Sr. Executive",
  },
  {
    img: img4,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Tuhin Jo",
    des: "Jr. executive",
  },
  {
    img: img1,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Tuhin Jo",
    des: "Jr. executive",
  },
  {
    img: img2,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Tuhin Jo",
    des: "Jr. executive",
  },
];

export const pricingData = [
  {
    leadText: "Our Pricing Plan",
    text: "Our Pricing Plan",
  },
];

export const pageRequestHeader = [
  {
    leadText: "Get a quote",
  },
];

export const pageRequestData = [
  [
    {
      tag: "select",
      classes: "form-control",
      label: "Freight Type",
    },
  ],
  [
    {
      tag: "select",
      classes: "form-control",
      label: "Incoterms",
    },
  ],

  [
    {
      tag: "input",
      type: "email",
      name: "departure",
      classes: "form-control",
      placeholder: "City of departure",
      label: "City of departure",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "city",
      classes: "form-control",
      placeholder: "Delivery city",
      label: "Delivery city",
    },
  ],
  [
    {
      tag: "input",
      type: "number",
      name: "weight",
      classes: "form-control",
      placeholder: "Total gross weight (KG)",
      label: "Total gross weight (KG)",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "dimension",
      classes: "form-control",
      placeholder: "Dimension",
      label: "Dimension",
    },
  ],
];

export const pageRequestData2 = [
  [
    {
      leadText: "Your Personal Details",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "fname",
      classes: "form-control",
      placeholder: "First Name",
      label: "First Name",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "lname",
      classes: "form-control",
      placeholder: "Last Name",
      label: "Last Name",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "ename",
      classes: "form-control",
      placeholder: "Email",
      label: "Your Email",
    },
  ],
  [
    {
      tag: "input",
      type: "number",
      name: "number",
      classes: "form-control",
      placeholder: "Phone Number",
      label: "Phone Number",
    },
  ],
  [
    {
      tag: "textarea",
      type: "text",
      name: "text",
      classes: "form-control",
      placeholder: "Your Messages",
      label: "Your Messages",
    },
  ],
  [
    {
      button: "Send Messages",
    },
  ],
];

export const ClientsData = [
  {
    img: img5,
    name: "Jhon Smith",
    des: "CEO of LTD company",
    para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
  },
  {
    img: img6,
    name: "Jhon Smith",
    des: "CEO of LTD company",
    para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
  },
  {
    img: img7,
    name: "Jhon Smith",
    des: "CEO of LTD company",
    para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
  },
  {
    img: img5,
    name: "Jhon Smith",
    des: "CEO of LTD company",
    para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
  },
  {
    img: img6,
    name: "Jhon Smith",
    des: "CEO of LTD company",
    para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
  },
  {
    img: img7,
    name: "Jhon Smith",
    des: "CEO of LTD company",
    para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`,
  },
];

export const GalleryData = [
  {
    img: img8,
  },
  {
    img: img9,
  },
  {
    img: img10,
  },
  {
    img: img11,
  },
  {
    img: img12,
  },
  {
    img: img13,
  },
];

export const FaqsData = [
  {
    heading: "TRANSPORT & LOGISTIC SERVICES",
    para: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`,
  },
  {
    heading: "SAFE AND FASTER LOGISTIC SERVICE NEER YOU",
    para: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`,
  },
  {
    heading: "DIGITAL SHIPPING SOLUTION",
    para: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`,
  },
  {
    heading: "WE PROVIDE BEST LOGISTIC SERVICES",
    para: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`,
  },
  {
    heading: "MODERN, SAFE AND TRUSTED LOGISTIC COMPANY",
    para: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`,
  },
  {
    heading: "OUR CLIENTS AROUND",
    para: `Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolore
omnis quaerat nostrum, pariatur ipsam sunt accusams enim
necessitatibus est fugiat, assumenda dolorem, deleniti corrupti
cupiditate ipsum, dolorum voluptatum esse error?`,
  },
];

export const questionFormHeader = [
  {
    leadText: "ASK YOUR QUESTIONS",
  },
];

export const questionFormData = [
  [
    {
      tag: "input",
      type: "text",
      name: "name",
      classes: "form-control ",
      placeholder: "Your Name",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "email",
      classes: "form-control ",
      placeholder: "Your Email",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "subject",
      classes: "form-control ",
      placeholder: "Your Subject",
    },
  ],
  [
    {
      tag: "textarea",
      type: "text",
      name: "name",
      classes: "form-control ",
      placeholder: "Type Your Messages",
    },
  ],
  [
    {
      button: "Submit",
    },
  ],
];

export const trackShipHeader = [
  {
    leadText: "Track Your Shipment",
    subText:
      "Solving your supply chain needs from end to end, taking the complexity out of container shipping. We are at the forefront of developing innovation.",
  },
];

export const trackShipData = [
  [
    {
      tag: "input",
      type: "text",
      name: "name",
      classes: "form-control",
      placeholder: "Name",
      label: "Name",
    },
  ],
  [
    {
      tag: "input",
      type: "email",
      name: "name",
      classes: "form-control",
      placeholder: "Email",
      label: "Email",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "tracking",
      classes: "form-control",
      placeholder: "Eg: AWB Num or CB Num",
      label: "Tracking Number",
    },
  ],
];
export const truckShipDate2 = [
  [
    {
      text1: "Date Range",
      text2: "to",
    },
  ],
  [
    {
      tag: "select",
      classes: "form-control",
      label: "Destination",
    },
  ],
  [
    {
      leadText: "Freight Type",
    },
  ],
  [
    {
      type: "radio",
      id: "by_air",
      name: "freight",
      value: "by_air",
      label: "By Air",
    },
  ],
  [
    {
      type: "radio",
      id: "by_road",
      name: "freight",
      value: "by_road",
      label: "By Road",
    },
  ],
  [
    {
      type: "radio",
      id: "by_sea",
      name: "freight",
      value: "by_sea",
      label: "By Sea",
    },
  ],
  [
    {
      button: "Track Now",
    },
  ],
];

export const signInInfo = [
  [
    {
      leadText: "Sign in to Your Account!",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "email",
      classes: "form-control",
      placeholder: "Username or Email",
    },
  ],
  [
    {
      tag: "input",
      type: "password",
      name: "password",
      classes: "form-control",
      placeholder: "password",
    },
  ],
  [
    {
      text: "Remember me",
    },
  ],
  [
    {
      button: "button",
      text: "Sign In",
    },
  ],
  [
    {
      text: "Not a member?",
      subText: "Sign Up",
    },
  ],
];

export const signOutInfo = [
  [
    {
      leadText: "Create an Account!",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "f_name",
      classes: "form-control",
      placeholder: "First Name",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "l_name",
      classes: "form-control",
      placeholder: "Last Name",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "user_name",
      classes: "form-control",
      placeholder: "User Name",
    },
  ],
  [
    {
      tag: "input",
      type: "text",
      name: "email",
      classes: "form-control",
      placeholder: "Email Address",
    },
  ],
  [
    {
      tag: "input",
      type: "password",
      name: "password",
      classes: "form-control",
      placeholder: "Password",
    },
  ],
  [
    {
      tag: "input",
      type: "password",
      name: "c_password",
      classes: "form-control",
      placeholder: "Confirm Password",
    },
  ],
  [
    {
      text: " I agree with Terms & Conditions",
    },
  ],
  [
    {
      tag: "button",
      subText: "Sign Up",
    },
  ],
  [
    {
      text1: "Already have an account?",
      text2: "Sign Up",
    },
  ],
];

export const privacyPolicyInfo = [
  [
    {
      leadText: "Digital Shipping Solution",
      para1:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
      para2:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil  molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla",
    },
  ],
  [
    {
      leadText: "Digital Shipping Solution",
      para1:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
      para2:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil  molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla",
    },
  ],
];

export const termsConditionInfo = [
  [
    {
      leadText: "Digital Shipping Solution",
      para1:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
      para2:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil  molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla",
    },
  ],
  [
    {
      leadText: "Digital Shipping Solution",
      para1:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim.",
      para2:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil  molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla  Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis",
    },
  ],
];

export const errorMessageInfo = [
  [
    {
      img: img14,
      leadText: "Page Not Found",
      link: "Back To Home",
    },
  ],
];
