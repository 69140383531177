import img1 from '../../../assets/img/service/serv-1.jpg'
import img2 from '../../../assets/img/service/serv-2.jpg'
import img3 from '../../../assets/img/service/serv-3.jpg'
import img4 from '../../../assets/img/service/serv-4.jpg'
import img5 from '../../../assets/img/service/serv-5.jpg'
import img6 from '../../../assets/img/service/serv-6.jpg'
import img7 from '../../../assets/img/service/serv-7.jpg'
import img8 from '../../../assets/img/service/serv-8.jpg'

export const ServiceData = [
    {
        img: img1,
        heading: "Road Freight Forwarding",
        para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
        button: "Read More"
    },
    {
        img: img2,
        heading: "Air Freight",
        para: "As a leader in global air freight forwarding, OIA Global excels in providing tailored transportation.",
        button: "Read More"
    },
    {
        img: img3,
        heading: "Road Freight Forwarding",
        para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
        button: "Read More"
    },
    {
        img: img4,
        heading: "Road Freight Forwarding",
        para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
        button: "Read More"
    },
    {
        img: img5,
        heading: "Road Freight Forwarding",
        para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
        button: "Read More"
    },
    {
        img: img1,
        heading: "Road Freight Forwarding",
        para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
        button: "Read More"
    },
    {
        img: img6,
        heading: "Road Freight Forwarding",
        para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
        button: "Read More"
    },
    {
        img: img7,
        heading: "Road Freight Forwarding",
        para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
        button: "Read More"
    },
    {
        img: img8,
        heading: "Road Freight Forwarding",
        para: "Cargo are transported at some stage of their journey along the world’s roads where we give you a presence.",
        button: "Read More"
    },
]