import React from 'react'

import AboutContent from './AboutContent'
import AboutSidebar from './AboutSideBar'


const About = () => {
    return (
        <>
            <section id="service_details_area">
                <div className="container">
                    <div className="row">
                        <AboutContent/>
                        <AboutSidebar/>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About
