import img1 from '../../../assets/img/common/feedback1.jpg'
import img2 from '../../../assets/img/common/feedback2.jpg'
import img3 from '../../../assets/img/common/feedback3.jpg'

export const ClientsData = [
    {
        img: img1,
        name: "Jhon Smith",
        des: "CEO of LTD company",
        para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`
    },
    {
        img: img2,
        name: "Jhon Smith",
        des: "CEO of LTD company",
        para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`
    },
    {
        img: img3,
        name: "Jhon Smith",
        des: "CEO of LTD company",
        para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`
    },
    {
        img: img1,
        name: "Jhon Smith",
        des: "CEO of LTD company",
        para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`
    },
    {
        img: img1,
        name: "Jhon Smith",
        des: "CEO of LTD company",
        para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`
    },
    {
        img: img1,
        name: "Jhon Smith",
        des: "CEO of LTD company",
        para: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium 
  doloremque laudantium,totam rem aperiam, eaque ipsa quae ab illo inventore 
  veritatis et quasi architecto beatae vitae dicta sunt explicabo.`
    }
]