import React from "react";
// Import Counter
// import Counter from "./Counter";
import { globalLeader } from "../../../GlobalJson/Home/Home";
// import {  CounterData } from "../../../GlobalJson/Home/Home";

const HomeAbout = () => {
  return (
    <>
      <section id="home_about_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              {globalLeader.map((item, index) => (
                <div className="about_img" key={index}>
                  <img src={item.img} alt="About_Img" style={{ height: 350 }} />
                </div>
              ))}
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="about_content">
                {globalLeader.map((item, index) => (
                  <div key={index}>
                    <div className="heading-left-border">
                      <h2>{item.leadText}</h2>
                    </div>
                    <h3>{item.subText}</h3>
                    <p>{item.text}</p>
                    <div className="about_sign_arae">
                      <div className="distaion_area">
                        <h5>{item.name}</h5>
                        <h6>{item.post}</h6>
                      </div>
                      <div className="signature">
                        {/* <img src={item.signature} alt="About_Img" /> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="about_counter_area">
            <div className="row">
              {CounterData.map((data, index) => (
                <Counter
                  countStart={data.countStart}
                  countEnd={data.countEnd}
                  heading={data.heading}
                  icon={data.icon}
                  key={index}
                />
              ))}
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default HomeAbout;
