import React from "react";
import { Link } from "react-router-dom";
import { TopHeaderData } from "../../GlobalJson/Nav/Nav";

// TopHeader Area
const TopHeader = () => {
  return (
    <>
      <div className="top-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <ul className="left-info">
                {TopHeaderData[0].map((item, index) => (
                  <li key={`${index}-left-top-nav`}>
                    <Link to={item.link}>
                      <i className={item.icon}></i>
                      {item.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <ul className="right-info">
                {TopHeaderData[1].map((item, index) => (
                  <li className="mr-20" key={`${index}-right-top-nav`}>
                    <Link to={item.link}>{item.text}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
