import React from "react";

import { aboutData } from "../../GlobalJson/About/About";

const ServiceContent = () => {
  return (
    <>
      <div className="col-lg-8">
        <div className="service_details_wrapper">
          {aboutData[0].map((data, index) => (
            <div className="service_details_items" key={index}>
              <img src={data.img} alt="img" />
              <div className="service_details_para">
                <h3>{data.leadText}</h3>
                <p>{data.para1}</p>
                <h3>{data.leadText2}</h3>
                <p>{data.para2}</p>
                <p>{data.para3}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiceContent;
