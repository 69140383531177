export const FaqsData = [
    {
        'heading': "TRANSPORT & LOGISTIC SERVICES",
        'para': `Transportation logistics is exclusively about managing material as it moves through the supply chain. This is directly related to how freights are hauled. Whether it's via truck, through the air, or by boat, this distribution model helps ensure all goods are being transported safely and efficiently.`
    },
    {
        'heading': "SAFE AND FASTER LOGISTIC SERVICE NEER YOU",
        'para': `We provide top-notch logistics services. Pay a deposit for your transactions with us and get discounted rates and faster deliveries`
    },
    {
        'heading': "DIGITAL SHIPPING SOLUTION",
        'para': `Find out how we are developing supply chain solutions that meet customer needs ... and there are various logistics solutions to meet your urgent shipping needs. ... with pioneering digital innovations to enable our customers to stay ahead.`
    },
    {
        'heading': "WE PROVIDE BEST LOGISTIC SERVICES",
        'para': `Transportation logistics specialists do a lot to make logistics and supply chain management easier, but they don't make as much as other supply chain professionals.`
    },
    {
        'heading': "MODERN, SAFE AND TRUSTED LOGISTIC COMPANY",
        'para': `Logistics can be split into five types by field: procurement logistics, production logistics, sales logistics, recovery logistics, and recycling logistics. Each of these is explained in detail, but first we should learn about logistics fields and types.`
    },
    {
        'heading': "OUR CLIENTS AROUND",
        'para': `Working with GlobeScan has really helped to widen and deepen our ... Clients. We partner with the world's most influential organizations and support them to ... of interviews with stakeholders around the world each year for our clients and we ...`
    }
]