import React from "react";
// import CommonBanner from '../component/Common/Banner'
import SignUpForm from "../component/SignUp";
import { signOutInfo } from "../GlobalJson/Pages/Pages";
import OurPartner from "../component/Common/OurPartner";

const SignUp = () => {
  return (
    <>
      {/* <CommonBanner heading="SignUp" page="SignUp"/> */}
      {signOutInfo[0].map((item, index) => (
        <SignUpForm key={index} heading={item.leadText} />
      ))}
      <OurPartner />
    </>
  );
};

export default SignUp;
