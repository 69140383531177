import React from "react";
import { Link } from "react-router-dom";
// Import Img

import { globalLeader } from "../../../GlobalJson/About/About";

const HomeTwoAbout = () => {
  return (
    <>
      <section id="service_about">
        <div className="container">
          {globalLeader.map((item, index) => (
            <div className="row" key={index}>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="about_service_img">
                  <img src={item.img} alt="img-about" />
                </div>
              </div>
              <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                <div className="about_service_text">
                  <div className="heading-left-border">
                    <h2>{item.leadText}</h2>
                  </div>
                  <p>{item.para1}</p>
                  <p>{item.para2}</p>
                  <Link to="/about" className="btn btn-theme">
                    {item.button}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default HomeTwoAbout;
