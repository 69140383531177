import img1 from "../../assets/img/common/about-service.jpg";
import img2 from "../../assets/img/common/team1.png";
import img3 from "../../assets/img/common/team2.png";
import img4 from "../../assets/img/common/team3.png";
import img5 from "../../assets/img/common/team4.png";
import img6 from "../../assets/img/partner/client-1.png";
import img7 from "../../assets/img/partner/client-2.png";
import img8 from "../../assets/img/partner/client-3.png";
import img9 from "../../assets/img/partner/client-4.png";
import img10 from "../../assets/img/partner/client-5.png";

import OurVisionImg from '../../assets/img/about/vision.png';
//Main About Page

export const aboutData = [
  [
    {
      img: OurVisionImg,
      leadText: "OUR MISSION",
      leadText2: 'WHO WE ARE?',
      para1:
        "To building transportation businesses with excellence!",
      para2:
        "Small Fleet Services provides professional dispatching and administrative services for owner operators and medium sized trucking companies. With more the 50 years of combined transportation experience; our teams' source top tier freight rates from trusted manufactures and brokers companies in the industry.",
      para3:
        "Helping carrier’s businesses grow are one of the hallmarks of our service. Building an entrepreneurial culture that is driven behind core values are the foundational pillars of our success and yours.",
      para4:
        "Making connections to build relationships with shippers and manufactures has in many cases led to contract opportunities in some form of continued business. Were excited about your journey and want to be a part of your growth..",
    },
  ],

  [
    {
      leadText: "Our Service",
    },
  ],
  [
    {
      text: "Finance Service",
      url: "/service",
    },
    {
      text: "Healthcare Consulting",
      url: "/service",
    },
    {
      text: "Strategic Marketing",
      url: "/service",
    },
    {
      text: "Contingency Plans",
      url: "/service",
    },
    {
      text: "Mergers & Acquisitions",
      url: "/service",
    },
    {
      text: "Marketing & Sales",
      url: "/service",
    },
  ],
  [
    {
      leadText: "Contact Us",
      office: "Visit our office",
      // address: "244, Cargo Hub, Los Angeles, California- 90005",
      subLeadText: "Call us on",
      text1: "Office: +689-203-7696",
      // text2: "Tollfree: 1800-123-45-6789",
      mailText: "Mail Us at",
      mail: "info@smallfleetdispatch.com",
    },
  ],
];

// ********************************//
export const globalLeader = [
  {
    img: img1,
    leadText: "Global Leader of the Logistics",
    para1:
      "Our warehousing services are known nationwide to be one of the most reliable, safe and affordable, because we take pride in delivering the best of warehousing services, at the most reasonable prices.",
    para2:
      "Pleasure and praising pain was born and I will give you a complete account of system, and expound actual teachings occasionally circumstances.",
    button: "More About Us",
  },
];

export const takingCare = [
  {
    leadText: "Taking care of you and your business all the way",
    text: "Solving your supply chain needs from end to end, taking the complexity out of container shipping. We are at the forefront of developing innovation.",
  },
];

export const team = [
  {
    leadText: "Our Team",
    text: "Solving your supply chain needs from end to end, taking thecomplexity out of container shipping. We are at the forefront of developing innovation.",
  },
];

export const TeamData = [
  {
    img: img2,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Kelina Mar",
    des: "Creative Director",
  },
  {
    img: img3,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Shamrat Dor",
    des: "Executive",
  },
  {
    img: img4,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Kazi Des",
    des: "Sr. Executive",
  },
  {
    img: img5,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Tuhin Jo",
    des: "Jr. executive",
  },
  {
    img: img1,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Tuhin Jo",
    des: "Jr. executive",
  },
  {
    img: img2,
    para: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae asperiores mollitia.",
    name: "Tuhin Jo",
    des: "Jr. executive",
  },
];

export const partnersData = [
  {
    leadText: "Our Partners:",
  },
  {
    img: img6,
  },
  {
    img: img7,
  },
  {
    img: img8,
  },
  {
    img: img9,
  },
  {
    img: img10,
  },
];
