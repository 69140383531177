import React from "react";
import AboutMain from "../component/about";

const About = () => {
  return (
    <div style={{ padding: 50 }}>
      <AboutMain />
    </div>
  );
};

export default About;
